import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from './core/services';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { LoadingIndicatorService } from './core/services/loading-indicator.service';
import { UserService } from './core/services/user.service';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
    title = 'lobster-frontend';
    loading$ = this.loader.loading$;
    agb = false;
    checked: boolean = false;
    link1 = $localize`https://www.lobster-event.com/de/allgemeine-nutzungsbedingungen`;
    link2 = $localize`https://www.lobster-event.com/de/datenschutzerklaerung`;

    constructor(
        private authService: AuthService,
        private http: HttpClient,
        private userService: UserService,
        private primengConfig: PrimeNGConfig,
        public loader: LoadingIndicatorService,
        public message: MessageService,
        private viewportScroller: ViewportScroller,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        /*this.loader.loading$.subscribe((data) => {
const body = document.getElementsByTagName('body')[0];
if (data === true) {
body.classList.add('show-loader');
} else {
body.classList.remove('show-loader');
}
});*/
    }

    ngOnInit(): void {
        const headers = new HttpHeaders();
        this.primengConfig.ripple = true;
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // Prüfen ob User eingeloggt.
                this.authService.userSubject.subscribe((user) => {
                    if (user && !event.urlAfterRedirects.includes('scheduling')) {
                        this.authService.getUser().then((u) => {
                            console.log(u);
                            if (u.loop_agb_accepted === null) {
                                // Prüfen, ob User AGB bereits bestätigt
                                this.agb = true;
                            }
                        });
                    }
                });
            }
        });
    }

    ngAfterViewInit(): void {}

    confirmAgb() {
        console.log(this.checked);
        if (this.checked === false) {
            this.showMessage($localize`Sie müssen unsere AGB und Datenschutzerklärung akzeptieren!`, 'error', $localize`:@@error:Fehler!`);
            return;
        }

        this.userService.cofirmAGBStatus().subscribe({
            next: (d) => {
                console.log(d);
                this.agb = false;
            },
            error: (d) => {
                this.showMessage($localize`Bei der Bestätigung der AGB ist ein Fehler aufgetreten!`, 'error', $localize`:@@error:Fehler!`);
            },
        });
    }

    showMessage(msg: string, type = 'error', summary = 'Fehler'): void {
        this.message.add({
            key: 'msgs',
            severity: type,
            life: 8000,
            summary,
            detail: msg,
        });
    }
}
