<div class="login" id="home-login-registration">
    <div class="inner-wrapper">
        <div class="logo">
            <img alt="Loop Plus" src="./assets/logos/loop-plus-text-white.svg"/>
        </div>

        <!--        <div class='headline' i18n='@@loginheadline'><span>loop+</span> Zugang!</div>-->

        <div class="form">
            <div #overlayMessage class="overlay-message">
                <div class="title"><span class="color-loop">Loop Plus</span>&nbsp;&nbsp;<span i18n="@@logintitle">Account Login</span></div>
                <p i18n="@@loopplus.hint">
                    Sofern du bereits einen Zugang zu Lobster Event hast, kannst du dich gleich hier einloggen. Falls dein Account noch nicht für Loop Plus freigeschaltet ist, schreibe uns bitte eine kurze Mail an loopplus&#64;lobster-event.com mit der Bitte um Freischaltung.
                </p>
                <div class="buttons">
                    <a href="mailto:loopplus@lobster-event.com">loopplus&#64;lobster-event.com</a>
                    <button (click)="hideOverlayMessage()" i18n>Verstanden</button>
                </div>
            </div>
            <!-- /#overlay-message -->
            <div class="inner">
                <div class="title"><span class="color-loop">Loop Plus</span>&nbsp;&nbsp;<span i18n="@@logintitle">Account Login</span></div>

                <form #loginForm="ngForm" (ngSubmit)="login()">
                    <div class="input">
                        <input [(ngModel)]="formData.username" autocomplete="false" i18n-placeholder="@@username" id="username" minlength="3" name="username" pInputText placeholder="Benutzername" required type="text"/>
                    </div>

                    <div class="input">
                        <p-password [(ngModel)]="formData.password"
                                    [feedback]="false"
                                    [toggleMask]="true"
                                    autocomplete="off"
                                    i18n-placeholder="@@password"
                                    id="password"
                                    minlength="3"
                                    name="password"
                                    placeholder="Passwort"
                                    required>
                        </p-password>
                    </div>

                    <button i18n-label="@@login" label="Login" pButton pRipple type="submit"></button>

                    <div class="lost-pw">
                        <a (click)="showPasswortReset(true)" i18n="@@lostpassword">Passwort vergessen?</a>
                    </div>
                </form>
            </div>
        </div>

        <div class="copy">
            <ng-container i18n="@@noaccountmessage">Du hast noch keinen Zugang?</ng-container>
            <br/>
            <a [routerLink]="['/register/step1']" i18n="@@registerhere">Hier registrieren</a>
        </div>
    </div>
    <!-- /.inner -->

    <app-meta-nav></app-meta-nav>

    <div id="background-images">
        <swiper-container #backgroundSwiper [config]="swiperBackgroundSlider" appSwiper init="false">
            <swiper-slide>
                <div class="image"><img alt="Loop Plus background 1" src="./assets/home/background-1.jpg"/></div>
            </swiper-slide>
            <swiper-slide>
                <div class="image"><img alt="Loop Plus background 2" src="./assets/home/background-2.jpg"/></div>
            </swiper-slide>
            <swiper-slide>
                <div class="image"><img alt="Loop Plus background 3" src="./assets/home/background-3.jpg"/></div>
            </swiper-slide>
        </swiper-container>
    </div>

    <div [ngClass]="{ show: passwortReset === true }" id="forgot-pw">
        <div class="inner">
            <div class="form">
                <div class="title" i18n="@@password.forgotten">Passwort vergessen?</div>
                <form #resetForm="ngForm" (ngSubmit)="resetPasswordData()" [class.ng-submitted]="resetSubmitted">
                    <input #mail="ngModel" [(ngModel)]="email" [ngClass]="{ 'p-invalid': mail.invalid }" id="float-input-position" minlength="3" name="email" pInputText pattern="^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$" placeholder="E-Mail" required type="email"/>
                    <button i18n-label="@@button.password.reset" iconPos="left" label="Passwort zurücksetzen" pButton pRipple type="submit"></button>
                </form>

                <button (click)="showPasswortReset(false)" class="close"></button>
            </div>
        </div>
    </div>
</div>
<!--/#home-page-wrapper -->
