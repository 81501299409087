import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const user = this.authService.userValue;
        const isApiUrl = request.url.startsWith(environment.appApi);
        const isServiceUrl = request.url.startsWith(environment.messageServiceUrl);
        const authUrl = request.url.startsWith(environment.appApi + '/oauth/token');
        const registerUrl = request.url.startsWith(environment.appApi + '/registration');
        const eventmobi = request.url.startsWith(environment.appApi + '/eventmobi');

        if (user && (isApiUrl || isServiceUrl) && !authUrl && !registerUrl && !eventmobi) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + user.access_token
                }
            });
        }

        return next.handle(request);
    }
}
