import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { RegistrationComponent } from './registration/registration.component';
import { PublicComponent } from './public/public.component';
import { ResetPasswordComponent } from './public/reset-password/reset-password.component';
import { HomeComponent } from './home/home.component';
import { Error404Component } from './modules/errors/error404/error404.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
    },

    {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'home',
        component: HomeComponent,
    },
    {
        path: 'scheduling',
        loadChildren: () => import('./features/scheduling/scheduling.routes'),
    },
    {
        path: 'fam-trips',
        loadChildren: () => import('./features/fam-trips/fam-trip.routes'),
    },
    {
        path: 'register',
        component: RegistrationComponent,
        loadChildren: () => import('./modules/registration/registration.module').then((m) => m.RegistrationModule),
    },
    {
        path: 'public',
        component: PublicComponent,
        children: [
            {
                path: 'password-reset/:token',
                component: ResetPasswordComponent,
            },
        ],
    },
    {
        path: 'account',
        loadChildren: () => import('./modules/account/account.module').then((m) => m.AccountModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'directory',
        loadChildren: () => import('./modules/directory/supplier-directory.module').then((m) => m.SupplierDirectoryModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'error/403',
        component: LoginComponent,
    },
    {
        path: '**',
        pathMatch: 'full',
        component: Error404Component,
    },
];

const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
