import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Injectable, InjectionToken, Injector, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {SharedModule} from './shared/shared.module';
import {CoreModule} from './core/core.module';
import * as Sentry from '@sentry/angular';

import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {AppRoutingModule} from './app-routing.module';
import {FormsModule} from '@angular/forms';
import {NgxsModule} from '@ngxs/store';
import {environment} from '../environments/environment';
import {NgxsLoggerPluginModule} from '@ngxs/logger-plugin';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {UserState} from './shared/state/user.state';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import {Error403Component} from './modules/errors/error403/error403.component';
import {RegistrationComponent} from './registration/registration.component';
import {RegisterState} from './shared/state/register.state';
import {PublicComponent} from './public/public.component';
import {ResetPasswordComponent} from './public/reset-password/reset-password.component';
import {Helper} from './shared/helper/helper';
import {HomeComponent} from './home/home.component';
import {Router} from '@angular/router';
import {Error404Component} from './modules/errors/error404/error404.component';
import {GlobalErrorHandler} from './core/handler/global-error.handler';
import {LayoutModule} from '@angular/cdk/layout';

// import function to register Swiper custom elements
import {register} from 'swiper/element/bundle';
import {ResizeDirective} from './shared/directives/resize.directive';
// register Swiper custom elements
register();

export const ERROR_HANDLERS = new InjectionToken<ErrorHandler[]>('ERROR_HANDLERS');

@Injectable({providedIn: 'root'})
export class ErrorHandlerManager implements ErrorHandler {
    constructor(private injector: Injector) {
    }

    handleError(error: any): void {
        const handlers = this.injector.get(ERROR_HANDLERS, []);
        handlers.forEach((handle) => handle.handleError(error));
    }
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        Error403Component,
        RegistrationComponent,
        PublicComponent,
        ResetPasswordComponent,
        HomeComponent,
        Error404Component,
    ],
    imports: [
        NgxsModule.forRoot([UserState, RegisterState], {
            developmentMode: !environment.production,
        }),
        NgxsLoggerPluginModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        NgxsStoragePluginModule.forRoot({keys: '*'}),
        BrowserModule,
        SharedModule,
        CoreModule,
        AppRoutingModule,
        FormsModule,
        BrowserAnimationsModule,
        LayoutModule,
        ResizeDirective,
    ],
    providers: [
        {
            provide: ERROR_HANDLERS,
            useClass: GlobalErrorHandler,
            multi: true,
        },
        {
            provide: ERROR_HANDLERS,
            useValue: Sentry.createErrorHandler({
                showDialog: false, // Zeigt Feedback-Form an
            }),
            multi: true,
        },
        {
            provide: ErrorHandler,
            useExisting: ErrorHandlerManager,
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {
            },
            deps: [Sentry.TraceService],
            multi: true,
        },
        MessageService,
        ConfirmationService,
        DialogService,
        Helper,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: []
})
export class AppModule {
}
